import React from 'react';

const Shop = () => (
  <div>
    <div className = "about">
        <p>Coming Soon!!!!</p>
        <p> If you have any ideas about what can be in the shop please tell me about it!</p>
    </div>
  </div>
);

export default Shop;
