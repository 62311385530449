import React, {useEffect, useState, Component} from 'react';
import Question from './Question';
import { Link } from 'react-router-dom';
import { getOrdinalHTML, updateRanks, updateOverallPoints, resetAnswered } from './Extra';
import { collection, query, orderBy, where, getDocs, addDoc, updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { db } from "./index";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Home = ({ accountInfo, questionID, course, questivians, questivianNames}) => {
  const [previousData, setPreviousData] = useState([]);
  const [chatData, setChatData] = useState([]);

  useEffect(() => {
    console.log("timer")
    
    const q = query(collection(db, `${course === "Ext1" ? course : ""}Question${questionID}`), orderBy("time"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).filter(item => item.correct === true);
      setPreviousData(newData);
    });

    return () => unsubscribe();

  }, [questionID]);

    useEffect(() => {
      console.log("timer")
      

    const q = query(collection(db, `chatRoom`), orderBy("time"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChatData(newData)
    });

    return () => unsubscribe();

    

  }, [questionID]);




  const handleSubmitChat = async () => {
    const message = document.getElementById('message-input').value.trim();
    if (message != "") {
      document.getElementById('message-input').value = "";
      if (accountInfo.account.name.split(' ')[0] != "Hongyi") {
        try {
          await addDoc(collection(db, `chatRoom`), {
            name: accountInfo.account.name.split(' ')[0],
            content: message,
            time: Date.now()
          });
        } catch (error) {
          console.error('Error writing document: ', error);
        }
      }
      setTimeout(1000);
      scrollToBottomChat();
    }
  };

  const convertChatDate = (chatDate) => {
    let text = "";
    if ((Date.now()-chatDate) < 1000*60*2) {
      text = "Just Now"
    } else if ((Date.now()-chatDate) < 1000*60*60*2) {
      text = `${Math.floor((Date.now()-chatDate)/(1000*60))} minutes ago`; 
    } else if ((Date.now()-chatDate) < 1000*60*60*24*2) {
      text = `${Math.floor((Date.now()-chatDate)/(1000*60*60))} hours ago`;
    } else {
      text = `${Math.floor((Date.now()-chatDate)/(1000*60*60*24))} days ago`;
    }
    return text;
  }

  const scrollToBottomChat = () => {
    document.getElementById('chat-messages').scrollTo({
      top: 9999999999999999

    });
  }


    //<h4 onclick={() => {document.getElementById("onlineUsernames").hidden = !document.getElementById("onlineUsernames").hidden}} id = "questivians">There {questivians == 1 ? "is" : "are"} {questivians == 0 ? "no other" : `${ (( (new Date()).getHours()) == 18) ? questivians+5 : questivians } fellow`} {questivians == 1 ? "Questivian" : "Questivians"} online. {questivians == 0 ? "It is just you." : ""}</h4>

  return <div>
    
    <h4 id="onlineUsernames" hidden="true">{questivianNames.map((item) => (<span>{item}, </span>))}</h4>
    <br/>
    
    {
      <Question accountInfo={accountInfo} questionID={questionID} course={course} />
    }
    <div className="smallleaderboard">
      <h2>Top 5</h2>
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>Name</th>
          </tr>

          {previousData.slice(0, 5).map((item, index) => (
            <tr key={item.id}>
              {getOrdinalHTML(index)}
              <th>{item.user}</th>
            </tr>
          ))}
        </tbody>
      </table>
      <p><Link to="/leaderboard"><u>More Information</u></Link></p>
    </div>

    <Popup
    trigger={<button className="button"> Open Modal </button>}
    modal
    nested
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header"> Modal Title </div>
        <div className="content">
          {' '}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
          Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
          delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
          <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
          commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
          explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
        </div>
        <div className="actions">
          <Popup
            trigger={<button className="button"> Trigger </button>}
            position="top center"
            nested
          >
            <span>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
              magni omnis delectus nemo, maxime molestiae dolorem numquam
              mollitia, voluptate ea, accusamus excepturi deleniti ratione
              sapiente! Laudantium, aperiam doloribus. Odit, aut.
            </span>
          </Popup>
          <button
            className="button"
            onClick={() => {
              console.log('modal closed ');
              close();
            }}
          >
            close modal
          </button>
        </div>
      </div>
    )}
  </Popup>
    
    
    <div class="chatroom">
            <div class="chat-header">
                <h2>Chat</h2>
            </div>
            <div class="chat-messages" id="chat-messages">
              <div id = "chat-content" class="chat-content">
                  {chatData.map((item,index) => (
                    <div class="message user-message" onLoad={index == chatData.length-1 ? scrollToBottomChat() : console.log()}>
                      <span class="chat-sender">{item.name}:  </span>
                      <span class="chat-time"> ({convertChatDate(parseInt(item.time))}) </span>
                      <span class="chat-message html">{item.content}</span>
                    </div>
                  ))}
                  <div id = "chat-loader"></div>
              </div>
            </div>
            <div class="chat-input">
                <input autocomplete="off" type="text" id="message-input" onKeyDown={(e) => {e.key === 'Enter' ? handleSubmitChat() : console.log()}} placeholder="Type a message..." />
                <button id="send-button" onClick={() => handleSubmitChat()}>Send</button>
            </div>
    </div> 


  </div>
};

export default Home;
