import React, { useState, useLayoutEffect, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import Comp from './Comp';
import Game from './Game';
import QA from './QA';
import About from './About';
import Leaderboard from './Leaderboard';
import Points from './Points';
import Messages from './Messages';
import Shop from './Shop';
import Admin from './Admin';
import Archive from './Archive';
import Header from './Header';
import Footer from './Footer';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './AuthProvider';
import Loading from './Loading';
import './style.css';
import { collection, query, where, getDocs, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from './index';

const isLocal = process.env.REACT_APP_IS_LOCAL === 'true';
const questionIDExt1 = 0;

/*
  ideas:
  show who is online
  update quote automatically
  auto provide rank and points
  
  shop
  streaks
  incorrect feedback
  prevent multiple submits

*/


function App() {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'dark';
  });

  const [course, setCourse] = useState(() => {
    return localStorage.getItem('course') || 'Ext2';
  });

  const [questionID, setQuestionID] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      let target = new Date(now);

      
      target.setHours(18, 30, 0, 0);

      if (now > target) {
        target.setDate(target.getDate() + 1);
      }
      const initialDate = new Date();
      initialDate.setFullYear(2024, 5, 9);
      initialDate.setHours(0,0,0,0)
      const timeLeft = target - initialDate;
      const daysPastStart = Math.floor(timeLeft/24/1000/60/60);
      setQuestionID(daysPastStart);

    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [questivians, setQuestivians] = useState(0);
  const [questivianNames, setQuestivianNames] = useState([]);
  const [accountInfo, setAccountInfo] = useState(null);

  const toggleCourse = () => {

  }

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useLayoutEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const updateActiveUsers = async (docId) => {
    setQuestivians(0);
    console.log("timer")

    /*
    const docRef = doc(db, 'users', docId);
  
    const intervalId = setInterval(async () => {
      await updateDoc(docRef, {
        lastActive: Date.now(),
      });

      const userQuery = query(collection(db, 'users'));
      const userSnapshot = await getDocs(userQuery);
      let count = 0;
  
      userSnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.email != docRef.email && userData.lastActive >= Date.now() - 10 * 60 * 1000) {
          count++;
        }
      });


  
      setQuestivians(count);
      
    }, 60 * 1000);
  
    return () => clearInterval(intervalId);
    */
  };
  
  
/*  useEffect(() => {
    if (accountInfo && accountInfo.account) {
      storeUser(accountInfo.account.name, accountInfo.account.userName)
        .then((docId) => {
          const cleanup = updateActiveUsers(docId);
          return () => {
            cleanup();
          };
        });
    }
  }, [accountInfo]);*/
  
  const storeUser = async (name, email) => {
    console.log("timer")
    const q = query(collection(db, 'users'), where('email', '==', email));
    const emailExists = await getDocs(q);
    let docId;
    if (emailExists.empty) {
      const docRef = await addDoc(collection(db, "users"), {
        name: name,
        email: email,
        time: Date.now(),
        lastTime: Date.now(),
        points: 0,
        lastQuestionAnswered: 0,
        lastQuestionMessaged: 0
      });
      docId = docRef.id;
    }
    else {
      /*
      const userRef = await getDocs(q);
      docId = userRef.docs[0].id;
      const docRef = doc(db, 'users', docId);
      
      await updateDoc(docRef, {
        lastTime: Date.now(),
        lastActive: Date.now(),
      });
      */
    }
    console.log("timer")

    const userQuery = query(collection(db, 'users'));
    const userSnapshot = await getDocs(userQuery);
    let count = 0;
    let usernamesOnline = []

    /*userSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (userData.email != email && userData.lastActive >= Date.now() - 10 * 60 * 1000) {
        count++;
        usernamesOnline.push(userData.name);
      }
    });*/

    setQuestivians(count);
    setQuestivianNames(usernamesOnline);

    return docId;
  };

  if (isLocal) {
    const dummyAccountInfo = { account: { name: 'Test User', userName: 'test@example.com' } };

    return (
      <div>
        <Router>
          <Header theme={theme} accountInfo={dummyAccountInfo} course = {course} questionID={questionID}/>
          <Routes>
            <Route path="/home" element={<Home accountInfo={dummyAccountInfo} questionID={questionID} course={course} questivians = {questivians} questivianNames = {questivianNames}/>} />
            <Route path="/comp" element={<Comp accountInfo={dummyAccountInfo} />} />
            <Route path="/qa" element={<QA />} />
            <Route path="/about" element={<About />} />
            <Route path="/leaderboard" element={<Leaderboard questionID={questionID} course={course}/>} />
            <Route path="/points" element={<Points />} />
            <Route path="/messages" element={<Messages questionID={questionID} course={course} />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/admin" element={<Admin questionID={questionID} accountInfo={accountInfo}  />} />
            <Route path="/archive" element={<Archive course = {course}/>} />
            <Route path="/comp/:roomId" element={<Comp accountInfo={dummyAccountInfo} />} />
            <Route path="/game/:roomId" element={<Game />} />
            <Route path="/" element={<Navigate to="/home" />} />
          </Routes>
          <Footer theme={theme} toggleTheme={toggleTheme} toggleCourse = {toggleCourse} course={course}/>
        </Router>
      </div>
    );
  } else {
    return (
      <AzureAD provider={authProvider} forceLogin={true}>
        {
          ({ login, logout, authenticationState, accountInfo }) => {
            if (authenticationState === "Unauthenticated") {
              return <Loading />;
            }

            if (authenticationState === "Authenticated") {
              //storeUser(accountInfo.account.name, accountInfo.account.userName);

              setAccountInfo(accountInfo);

              return (
                <div>
                  <Router>
                    <Header theme={theme} accountInfo={accountInfo} course = {course} questionID={questionID}/>
                    <Routes>
                      <Route path="/home" element={<Home accountInfo={accountInfo} questionID={questionID} course={course} questivians = {questivians} questivianNames = {questivianNames}/>} />
                      <Route path="/comp" element={<Comp accountInfo={accountInfo}/>} />
                      <Route path="/qa" element={<QA />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/leaderboard" element={<Leaderboard questionID={questionID} course={course}/>} />
                      <Route path="/points" element={<Points />} />
                      <Route path="/messages" element={<Messages questionID={questionID} course={course}/>} />
                      <Route path="/shop" element={<Shop />} />
                      <Route path="/admin" element={<Admin accountInfo={accountInfo} questionID={questionID} />} />
                      <Route path="/archive" element={<Archive course = {course}/>} />
                      <Route path="/comp/:roomId" element={<Comp accountInfo={accountInfo} />} />
                      <Route path="/game/:roomId" element={<Game />} />
                      <Route path="/" element={<Navigate to="/home" />} />
                    </Routes>
                    <Footer theme={theme} toggleTheme={toggleTheme} toggleCourse = {toggleCourse} course={course}/>
                  </Router>
                </div>
              );
            }

            return null;
          }
        }
      </AzureAD>
    );
  }
}

export default App;
export { questionIDExt1 };