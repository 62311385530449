import React from 'react';
import Points from './Points';

const About = () => (
  <div>
    <div className = "about">
		<p>Welcome to Questive! This website serves as a fun and competitive gamified platform to practice your Extension 2 problem-solving skills. Here's a snapshot of what's in store:
		</p>
		<p>1. Daily Problem: Every evening at 6pm, a fresh Extension 2 maths problem is served up for your analytical enjoyment. It's not just about solving the problem but being the quickest to do so. As a reward, the first solver can display a custom message on the website for everyone to see, updated at 6pm. Remember, practice makes perfect, especially with HSC looming on the horizon.
		</p>
		<p>2. Competition Mode: Coming Soon! Ever wanted to put your problem-solving speed to the test against another player? Here's your chance! You're against the clock, racing to solve as many problems as you can before time runs out.
		</p>
		<p>3. Questions and Answers: Coming Soon! Have a nagging maths question? Or perhaps you want to share your knowledge with others? This interactive feature allows you to post and answer maths-related questions, fostering a spirit of learning and collaboration.
		</p>
		<p>As you solve problems, engage in competitions, and participate in the Q&A, you'll earn points. Keep an eye on these - they might translate into some exciting year-end rewards!
		</p>
		<p>So, dive in and explore Questive! Happy problem-solving!
		</p>
		<p>Have any questions or feedback? Message me on the <a href = "https://discord.gg/p2J8rFQWZk" target="_blank"><u><b>Questive Discord Server</b></u></a>.</p>
	</div>

	<Points/>
  </div>
);

export default About;
